<template>
  <BFormGroup
    :label="label"
  >
    <div
      @mouseover="formGroupHovered = true"
      @mouseleave="formGroupHovered = false"
    >
      <VueAutosuggest
        :ref="reference"
        v-model="selected"
        :component-attr-id-autosuggest="'kmtx-domainlist-' + index"
        :input-props="{
          id: 'autosuggest-' + index + '__input',
          class: 'form-control',
          placeholder: placeholder,
        }"
        :suggestions="filteredData"
        :limit="Infinity"
        class="domain-list"
        @selected="onSelectHandler"
        @input="onInputChange"
      >
        <template
          v-if="showClearBtn"
          #before-input
        >
          <BButton
            class="domain-list__clear-btn"
            size="sm"
            variant="outline-light"
            @click="onSelectHandler(defaultSuggestion)"
          >
              <Icon
                  image="/close.svg"
                  class="domain-list__icon"
                  :size="{ width: '8px', height: '8px' }"
              />
          </BButton>
        </template>
        <template #default="{ suggestion }">
          {{ suggestion.item.name }}
        </template>
      </VueAutosuggest>
    </div>
  </BFormGroup>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';
import Icon from '@/components/IconsComponent.vue';

export default {
  name: 'KmtxDomainlist',
  components: {
      Icon,
      VueAutosuggest,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: 'Domains',
    },
    placeholder: {
      type: String,
      default: 'Type an domain...',
    },
    reference: {
      type: String,
      default: 'kmtx-domainlist',
    },
    filterOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filteredData: [],
      formGroupHovered: false,
    };
  },
  computed: {
    selected: {
      get() {
        return this.currentDomain.name;
      },
      set(newValue) {
        return newValue;
      },
    },
    ...mapState('newFilters', ['currentDomain', 'domains']),
    ...mapState('source', ['sourceLabels']),
    showClearBtn() {
      return this.formGroupHovered && this.selected && this.selected !== 'All';
    },
    defaultSuggestion() {
      return { item: this.domains.find((domain) => domain.id === 0) };
    },
  },
  created() {
    if (this.domains.length === 0) {
      this.fillSourceLabels().then(() => {
        this.setDomains(this.sourceLabels);
        this.init();
      }).catch((error) => { if (!error.cancelReason) throw error; });
    } else {
      this.init();
    }
  },
  beforeDestroy() {
    this.setCurrentDomainByQueryParams();
  },
  methods: {
    ...mapActions('source', ['fillSourceLabels']),
    ...mapMutations('newFilters', ['updateFilterByIndex']),
    ...mapActions('newFilters', {
      setDomains: 'setDomains',
      setCurrentDomain: 'setCurrentDomain',
      setCurrentDomainByQueryParams: 'setCurrentDomainByQueryParams',
    }),
    onSelectHandler(selected) {
      if (selected !== null && selected.item.name !== undefined
                && this.currentDomain.name !== selected.item.name) {
        this.setCurrentDomain(selected.item);
        this.changeQueryParams(selected.item.id === 0);
        const filter = {
          filter_value: selected.item.id,
          filter_options: {
            label_type: selected.item.labelType,
          },
        };
        this.updateFilterByIndex({ index: this.index, filter });
        this.formGroupHovered = false;
      }
    },
    onInputChange(domain) {
      domain = domain.trim().toLowerCase();

      if (domain !== null && domain.length > 0 && this.domains.length > 0) {
        let filteredData = this.domains.filter((item) => {
          if (item.name !== undefined) {
            return item.name.trim().toLowerCase().indexOf(domain) > -1;
          }
        });

        filteredData = this.sortData(filteredData, domain);

        this.filteredData = [{ data: filteredData }];
      }
    },
    sortData(data, input) {
      const alphabetSorted = data.sort((a, b) => a.name.localeCompare(b.name));

      const preSort = [];
      const postSort = [];

      alphabetSorted.forEach((item) => {
        if (item.name.toLowerCase().indexOf(input) === 0) {
          preSort.push(item);
        } else if (item.name.toLowerCase().indexOf(input) > 0) {
          postSort.push(item);
        }
      });

      return preSort.concat(postSort);
    },
    changeQueryParams(isDefault) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          host: isDefault ? undefined : this.currentDomain.id,
          labeltype: isDefault ? undefined : this.currentDomain.labelType,
        },
      });
    },
    init() {
      // if host and labelType exist and not match same params in store
      const changeDomain = (!!this.$route.query.host && !!this.$route.query.labeltype)
                && (Number(this.$route.query.host) !== this.currentDomain.id
                || this.$route.query.labeltype !== this.currentDomain.labelType);

      if (changeDomain) {
        this.setCurrentDomainByQueryParams(this.$route.query);
      }
      // if query had invalid data => remove host and labeltype from query string
      if (this.$route.query.host && (Number(this.$route.query.host) !== this.currentDomain.id || this.currentDomain.id === 0)) {
        this.changeQueryParams(true);
      }

      const filter = {
        filter_value: this.currentDomain.id,
        filter_options: {
          label_type: this.currentDomain.labelType,
        },
      };
      this.updateFilterByIndex({ index: this.index, filter });
    },
  },
};
</script>

<style lang="scss">
.domain-list {
  min-width: 210px;
  position: relative;
}

.domain-list__clear-btn {
  position: absolute;
  height: calc(100% - 2px);
  bottom: 1px;
  right: 1px;
  &.btn {
    background-color: #fff;
  }
}
</style>
