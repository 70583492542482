import { skinList } from '@/const';

// eslint-disable-next-line import/no-cycle
import SourceService from '@/services/Source';

const state = {
    sourceLabels: [],
    routes: [],
    themeMap: {},
};

const actions = {
    fillSourceLabels({ commit }: any, params: any) {
        return SourceService.getSourceLabels(params).then((response) => {
            commit('setSourceLabels', response.data.data);

            return response;
        });
    },

    fillThemeMap({ commit }: any, params: any) {
        params = params || {};
        params.expand = 'sources';

        return SourceService.getLabels(params).then((response) => {
            commit('setThemeMap', response.data.data);

            return response;
        });
    },

    fillRoutes({ commit }: any, params: any) {
        return SourceService.getRoutes(params).then((response) => {
            commit('setRoutes', response.data.data);

            return response;
        });
    },
};

const mutations = {
    setSourceLabels(state: any, payload: any) {
        const sLabels = Object.create(null);
        state.sourceLabels = Object.create(null);
        for (const p in payload) {
            if (!Object.prototype.hasOwnProperty.call(payload, p)) {
                continue;
            }

            if (typeof sLabels[payload[p].host] === 'undefined') {
                sLabels[payload[p].host] = {
                    id: payload[p].id,
                    host: payload[p].host,
                    is_active: payload[p].is_active,
                };
            }

            if (payload[p].labels) {
                // iterate labels for each source
                for (const l in payload[p].labels) {
                    if (!Object.prototype.hasOwnProperty.call(payload[p].labels, l)) {
                        continue;
                    }

                    const labelName = payload[p].labels[l].name;

                    if (typeof sLabels[labelName] === 'undefined') {
                        sLabels[labelName] = {
                            id: payload[p].labels[l].id,
                            host: payload[p].labels[l].name,
                            label: true,
                        };
                    }
                }
            }
        }
        sLabels.All = {
            id: 0,
            host: 'All',
        };
        const sourceLabels = Object.keys(sLabels).map((key) => {
            // [1,1,2,3,3,3,4,5,5] => [1,2,3,4,5]
            sLabels[key].hosts = [...new Set(sLabels[key].hosts)];
            return sLabels[key];
        });

        state.sourceLabels = sourceLabels;
        return sourceLabels;
    },

    setRoutes(state: any, payload: any) {
        state.routes = payload;
    },

    setThemeMap(state: any, payload: any) {
        state.themeMap = {};
        for (const i in payload) {
            const label = payload[i];
            if (skinList.indexOf(label.name) < 0) {
                continue;
            }
            if (label.sources.length > 0) {
                label.sources.map((s: any) => {
                    if (state.themeMap[s.id]) {
                        state.themeMap[s.id].push(label.name);
                    } else {
                        state.themeMap[s.id] = [label.name];
                    }
                });
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
