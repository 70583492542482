<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <component
    :is="inFormGroup ? 'b-form-group' : 'div'"
    :label="label"
  >
    <div
      @mouseover="formGroupHovered = true"
      @mouseleave="formGroupHovered = false"
    >
      <VueAutosuggest
        :ref="reference"
        v-model="selected"
        :component-attr-id-autosuggest="'kmtx-autosuggest-' + index"
        :input-props="{
          id: 'autosuggest-' + index + '__input',
          class: 'form-control',
          placeholder: placeholder,
          required: required,
          disabled: disabled,
        }"
        :suggestions="filteredData"

        :limit="options.limit"

        class="kmtx-filter-autosuggest"
        @selected="onSelectHandler"
        @input="onInputChange"
      >
        <template
          v-if="showClearBtn"
          #before-input
        >
          <BButton
            class="autosuggest-list__clear-btn"
            size="sm"
            variant="outline-light"
            @click="onSelectHandler(defaultSuggestion)"
          >
            <Icon
              image="close.svg"
              class="domain-list__clear-icon"
              label="Close"
              :size="{ width: '12px', height: '12px' }"
            />
          </BButton>
        </template>
        <template #default="{ suggestion }">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="d-flex flex-column">
            <span v-html="emNeedle(suggestion.item.name, selected, suggestion)" />
            <span
              v-if="suggestion.item.secondary_text"
              class="secondary-text"
            >
              {{ suggestion.item.secondary_text }}
            </span>
          </div>
        </template>
      </VueAutosuggest>
    </div>
  </component>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest';
import IconsComponent from '@/components/IconsComponent.vue';
import EventBus from '@/event.ts';

export default {
  name: 'KmtxAutosuggest',
  components: {
    VueAutosuggest,
    Icon: IconsComponent,
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: 'Select',
    },
    data: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
    filterByValue: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'Type to search...',
    },
    required: {
      type: Boolean,
      default: false,
    },
    reference: {
      type: String,
      default: 'kmtx-autosuggest',
    },
    options: {
      type: Object,
      default: () => ({
        limit: Infinity,
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inFormGroup: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['autosuggest-selected', 'autosuggest-input'],
  data() {
    return {
      selected: '',
      filteredData: [],
      formGroupHovered: false,
    };
  },
  computed: {
    showClearBtn() {
      return this.formGroupHovered && this.selected && !this.disabled;
    },
    defaultSuggestion() {
      return { item: { id: null, name: '' } };
    },
  },
  watch: {
    data: {
      handler(newData, oldData) {
        if (newData !== oldData) {
          this.filteredData = [{ data: this.sortData(newData, this.filterByValue ? this.selected : '') }];
        }
      },
      immediate: true,
    },
    value: {
      immediate: true,
      handler(to) {
        this.selected = to;
      },
    },
  },
  created() {
    EventBus.$on('kmtx-update-auto-suggest-value', ({ reference, value = '' }) => {
      if (this.reference === reference) {
        this.selected = value;
      }
    });
  },
  methods: {
    onSelectHandler(selected) {
      if (selected !== null) {
        this.$emit('autosuggest-selected', {
          reference: this.reference,
          data: selected.item,
          autosuggestIndex: this.index,
        });
        this.selected = selected.item.name;
        this.filteredData = [{ data: this.sortData(this.data, this.selected) }];
        this.formGroupHovered = false;
      }
    },
    /**
     * Change the selected value without notification and filtering (used externally)
     * @param {string} selected - Item name
     */
    silentSelect(selected) {
      this.selected = selected;
      this.filteredData = [{ data: this.sortData(this.data, '') }];
    },
    onInputChange(value) {
      this.selected = value;

      const preparedValue = value.trim().toLowerCase();
      if (this.data.length > 0) {
        let filteredData = this.data.filter((item) => {
          if (item.name !== undefined) {
            return item.name.trim().toLowerCase().indexOf(preparedValue) > -1;
          }
        });

        filteredData = this.sortData(filteredData, preparedValue);

        this.filteredData = [{ data: filteredData }];

        if (filteredData.length > 0) {
          this.$emit('autosuggest-input', {
            reference: this.reference,
            data: filteredData[0],
            autosuggestIndex: this.index,
          });
        }
      }
    },
    sortData(data, input) {
      const preparedInput = input.trim().toLowerCase();
      const alphabetSorted = data.sort((a, b) => a.name.localeCompare(b.name));

      const preSort = [];
      const postSort = [];

      alphabetSorted.forEach((item) => {
        const name = item.name.trim().toLowerCase();
        if (name.indexOf(preparedInput) === 0) {
          preSort.push(item);
        } else if (name.indexOf(preparedInput) > 0) {
          postSort.push(item);
        }
      });

      return preSort.concat(postSort);
    },
    emNeedle(string, needle) {
      const preparedNeedle = needle?.toLowerCase() || '';

      const startEm = '<b>';
      const endEm = '</b>';

      const needleLength = preparedNeedle.length;
      const needleStartPosition = string.toLowerCase().indexOf(preparedNeedle);
      const needleEndPosition = needleStartPosition + needleLength + startEm.length;

      let newString = '';

      if (needleStartPosition !== -1) {
        newString = this.insertSubstring(string, startEm, needleStartPosition);
        newString = this.insertSubstring(newString, endEm, needleEndPosition);
      } else {
        newString = string;
      }

      return newString;
    },
    insertSubstring(string, substring, startPosition) {
      return string.slice(0, startPosition) + substring + string.slice(startPosition);
    },
  },
};
</script>

<style lang="scss">
    .kmtx-filter-autosuggest {
        min-width: 210px;
        position: relative;
        .secondary-text {
            color: #94989c;
            font-size: 10px;
            font-weight: 300;
            line-height: 1;
        }
    }

    .autosuggest-list__clear-btn {
        position: absolute;
        height: calc(100% - 2px);
        bottom: 1px;
        right: 1px;
        &.btn {
            background-color: #fff;
        }

        .domain-list__clear-icon {
            transform: scale(.8);
        }
    }
</style>
