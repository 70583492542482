import LocalStorage from '@/services/LocalStorage';

const Connections = () => import(/* webpackChunkName: "[Connections]" */ '@/components/integrations/Connections.vue');
const DataStream = () => import(/* webpackChunkName: "[DataStream]" */ '@/components/integrations/DataStream.vue');
const ReportLogs = () => import(/* webpackChunkName: "[ReportLogs]" */ '@/components/integrations/ReportLogs.vue');
const ConnectionReportForm = () =>
    import(/* webpackChunkName: "[ConnectionReportForm]" */ '@/components/integrations/ConnectionReportForm.vue');
const AvailableIntegrations = () =>
    import(/* webpackChunkName: "[AvailableIntegrations]" */ '@/components/integrations/AvailableIntegrations.vue');
const KeywordToolList = () => import(/* webpackChunkName: "[KeywordToolList]" */ '@/modules/integrations/pages/KeywordToolList.vue');
const ReportCreate = () => import(/* webpackChunkName: "[ReportCreate]" */ '@/modules/integrations/pages/ReportCreate.vue');
const ReportEdit = () => import(/* webpackChunkName: "[ReportEdit]" */ '@/modules/integrations/pages/ReportEdit.vue');
const KeywordEdit = () => import(/* webpackChunkName: "[KeywordEdit]" */ '@/modules/integrations/pages/KeywordEdit.vue');
const partyServicesBrowserTitle = '3-rd Party Services - Data Platform';

// 3-rd party services integrations
export default [
    {
        path: '/integrations/connections',
        component: Connections,
        name: 'integration-connections',
        meta: {
            header: { title: 'Connections' },
            permission: 'esc:integrations:connections',
            browserTitle: `Connections - ${partyServicesBrowserTitle}`,
        },
    },
    {
        path: '/integrations/data-stream',
        component: DataStream,
        name: 'integration-data-stream',
        meta: {
            header: { title: 'Data Stream' },
            permission: 'esc:integrations:data-stream',
            browserTitle: `Data Stream - ${partyServicesBrowserTitle}`,
        },
    },
    {
        path: '/integrations/logs',
        component: ReportLogs,
        name: 'reports-logs',
        meta: { permission: 'esc:integrations:logs', browserTitle: `Logs - ${partyServicesBrowserTitle}` },
    },
    {
        path: '/integrations/report',
        component: ConnectionReportForm,
        name: 'connection-report',
        meta: { permission: 'esc:integrations:data-stream:view', browserTitle: `Report - ${partyServicesBrowserTitle}` },
    },
    {
        path: '/integration-connectors',
        component: AvailableIntegrations,
        name: 'integration-connectors',
        meta: { header: { title: 'Integrations' } },
    },

    {
      path: '/integrations/seo-tool',
      component: KeywordToolList,
      name: 'seo-tool',
      meta: { permission: 'seotool:reports:report:view', browserTitle: `Seo Tool - ${partyServicesBrowserTitle}` },
    },
    {
      path: '/integrations/seo-tool/create',
      name: 'seo-tool-create',
      component: ReportCreate,
      meta: { permission: 'seotool:reports:report:create', browserTitle: `Create Seo Tool - ${partyServicesBrowserTitle}` },
    },
    {
      path: '/integrations/seo-tool/:id',
      name: 'seo-tool-edit',
      component: ReportEdit,
      meta: { permission: 'seotool:reports:report:view', browserTitle: `Edit Seo Tool - ${partyServicesBrowserTitle}` },
    },
    {
      path: '/integrations/seo-tool/:id/keyword/:keywordId',
      name: 'keyword-edit',
      component: KeywordEdit,
      meta: { permission: 'seotool:reports:keyword:view', browserTitle: `Edit Keyword - Seo Tool - ${partyServicesBrowserTitle}` },
    },
];
