<template>
    <nav class="header-navigation">
        <ul class="header-navigation__list">
            <li
                v-for="(item, index) in headerMenu"
                :id="item.title + index"
                :key="index"
                class="header-navigation__item"
                :class="{ 'header-navigation__item--active': item.title === activeTab.title }"
                @click="openMainMenu(item)"
            >
                <Link
                    :to="item.subMenu[0].name"
                    class="header-navigation__item-link"
                >
                    <TooltipMenu
                        :target="item.title + index"
                        :menu-item="item"
                    />
                    <Icon
                        :image="`/menu/${item.icon.toLowerCase()}`"
                        class="header-navigation__icon"
                    />
                    <span class="header-navigation__title text-overflow--ellipsis">{{ item.title }}</span>
                </Link>
            </li>
        </ul>

        <ul
            v-if="activeTab.subMenu"
            class="header-navigation__list header-navigation__list--sub"
        >
            <li
                v-for="subItem in activeTab.subMenu.filter((el) => !el.hidden)"
                :key="subItem.id"
                class="header-navigation__item"
                @click.stop="openSubMenu(subItem)"
            >
                <Link
                    :to="subItem.name"
                    class="header-navigation__link text-overflow--ellipsis"
                >
                    {{ subItem.title }}
                </Link>
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import menu from '@/menu';
import Icon from '@/components/IconsComponent.vue';
import Link from '@/components/Link.vue';
import TooltipMenu from '@/components/ui/TooltipMenu.vue';
import EventBus from '@/event';
import Permissions from '@/mixins/Permissions.vue';
import deepmerge from 'deepmerge';

export default {
    components: {
        Icon,
        TooltipMenu,
        Link,
    },

    mixins: [Permissions],

    data() {
        return {
            activeTab: '',
        };
    },

    computed: {
        ...mapState('router', ['isRouteChangeAvailable']),
        ...mapState('auth', ['currentUser']),
        ...mapGetters('auth', ['masterRole', 'currentAccount']),

        canMasterAccess() {
            return this.currentUser?.type === 1;
        },

        headerMenu() {
            const configuredHeaderMenu = [];

            // clone nested array
            const tempMenu = deepmerge.all([menu]);

            for (let i = 0; i < tempMenu.length; i++) {
                const item = { ...tempMenu[i] };

                // MENU ITEM
                if (this.shouldSkipMenuItem(item)) continue;

                // allow for master user
                if (item?.allowForMaster && this.masterRole) {
                    configuredHeaderMenu.push(item);
                    continue;
                }

                // SUBMENU ITEM
                const isExistSubmenu = this.hasSubMenu(item);
                if (isExistSubmenu) {
                    const subMenuItems = [...item.subMenu];
                    item.subMenu = [];

                    // check access to subMenu items
                    while (subMenuItems.length > 0) {
                        if (subMenuItems[0].hasSomePermission) {
                            const isAvailable = subMenuItems[0].hasSomePermission
                                .map((permission) => this.$can(permission))
                                .some((element) => element === true);
                            if (!isAvailable) {
                                subMenuItems.shift();
                                continue;
                            }
                        }

                        if (
                            (subMenuItems[0]?.allowForMaster && this.masterRole === true) || // allow for master
                            (subMenuItems[0]?.masterAccountOnly && !this.currentAccount.parent_id) // allow only for master accounts
                        ) {
                            item.subMenu.push(subMenuItems[0]);
                            subMenuItems.shift();
                            continue;
                        }

                        if (subMenuItems[0]?.childAccountOnly) {
                            if (this.currentAccount.parent_id && (!subMenuItems[0].permission || this.$can(subMenuItems[0].permission))) {
                                item.subMenu.push(subMenuItems[0]);
                            }
                            subMenuItems.shift();
                            continue;
                        }

                        if (
                            (subMenuItems[0]?.onlyServiceMaster && !this.canMasterAccess) || // allow for service master
                            (subMenuItems[0].permission && !this.checkPermissions(subMenuItems[0])) || // check permission in current item
                            subMenuItems[0]?.masterAccountOnly
                        ) {
                            subMenuItems.shift();
                            continue;
                        }

                        // SIDEBAR MENU
                        let originalSideBarDeps = 0;
                        let countSideBarDeps = 0;

                        if (subMenuItems[0].sidebarMenu) {
                            const sideBarMenu = [...subMenuItems[0].sidebarMenu.items];
                            subMenuItems[0].sidebarMenu.items = [];
                            originalSideBarDeps = sideBarMenu.length;

                            while (sideBarMenu.length > 0) {
                                const sideBarItem = sideBarMenu.shift();

                                if (this.shouldSkipSideBarItem(sideBarItem)) continue;

                                subMenuItems[0].sidebarMenu.items.push(sideBarItem);
                                countSideBarDeps++;
                            }
                        }

                        // add submenu item if not have any sidebar or includes allowed sidebar items
                        if (originalSideBarDeps === 0 || countSideBarDeps > 0) item.subMenu.push(subMenuItems[0]);
                        subMenuItems.shift();
                    }
                }

                // add main menu item if not have any submenu items or includes allowed submenu items
                if (!isExistSubmenu || (isExistSubmenu && item.subMenu.length > 0)) configuredHeaderMenu.push(item);
            }

            return configuredHeaderMenu;
        },
    },

    watch: {
        currentUser(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.openMenuAfterReloadPage();
            }
        },

        $route(to) {
            this.openMenuAfterReloadPage(to.path);
        },
    },

    mounted() {
        this.openMenuAfterReloadPage();
    },

    methods: {
        shouldSkipMenuItem(item) {
            // check 'hasSomePermission' field
            if (item.hasSomePermission) {
                const isAvailable = item.hasSomePermission.map((permission) => this.$can(permission)).some((element) => element === true);
                if (!isAvailable) return true;
            }

            // check roles
            if (
                (item.masterAccountOnly && !this.currentAccount.parent_id) || // only master accounts
                (item.onlyServiceMaster && !this.canMasterAccess) || // only service master
                (item.permission && !this.checkPermissions(item, 'view'))
            ) {
                // check permissions
                return true;
            }
            return false;
        },

        shouldSkipSideBarItem(sideBarItem) {
            return (
                (sideBarItem.onlyServiceMaster && !this.canMasterAccess) || // only service master
                (sideBarItem.allowForMaster && this.masterRole) || // only master
                (sideBarItem.permission && !this.checkPermissions(sideBarItem)) // check permissions
            );
        },

        hasSubMenu(item) {
            return Object.prototype.hasOwnProperty.call(item, 'subMenu');
        },

        checkPermissions(item, type = 'view') {
            const available = Array.isArray(item.permission)
                ? item.permission.reduce((acc, permission) => acc + this.$has(permission, type), 0)
                : this.$has(item.permission, type);
            return available > 0;
        },

        navigateTo(path) {
            this.$router.push(path).catch((error) => {
                console.debug(error.message);
            });
        },

        sidebarCollapsed(sidebarMenu = null) {
            EventBus.$emit('kmtx-sidebar-collapsed', sidebarMenu);
        },

        headerCollapsed(value = false) {
            if (!value) this.activeTab = '';
            EventBus.$emit('kmtx-header-collapsed', value);
        },

        openMenuAfterReloadPage(path) {
            if (!this.isRouteChangeAvailable) return;

            let match = false;
            const actualPath = path || window.location.pathname;
            this.activeTab = '';

            // check the match with menu items
            this.headerMenu.forEach((item) => {
                item.subMenu.forEach((sub) => {
                    if (actualPath.match(`^${sub.name}`)) {
                        match = true;
                        this.openMainMenu(item, sub, true);
                    }
                });
            });

            // no matches with menu items
            if (!match) {
                this.sidebarCollapsed();
                this.headerCollapsed(false);
            }
        },

        openMainMenu(item, subItem = false, isFromReload = false) {
            if (!this.isRouteChangeAvailable) return;

            // switch main menu
            this.activeTab = item;
            this.sidebarCollapsed();

            // switch sub menu
            const hasSubMenu = item?.subMenu?.length;
            const subMenuItem = hasSubMenu ? item.subMenu[0] : null;
            const path = subMenuItem?.sidebarMenu?.items[0]?.name || subMenuItem?.name;

            if (hasSubMenu) {
                this.headerCollapsed(true);
                if (!isFromReload) this.navigateTo(path);
                this.openSubMenu(subItem || subMenuItem, isFromReload);
            } else if (item?.sidebarMenu?.items) {
                this.openSubMenu(subItem || item, isFromReload);
            }
        },

        openSubMenu(subItem, isFromReload) {
            if (!this.isRouteChangeAvailable) return;

            // switch sub menu
            this.sidebarCollapsed();

            // switch sidebar menu
            const sidebarMenu = subItem?.sidebarMenu;
            if (sidebarMenu?.items.length) {
                this.sidebarCollapsed(sidebarMenu);
                const actualItem = sidebarMenu.items[0];

                if (!isFromReload) this.navigateTo(actualItem.name);

                this.headerCollapsed(!!sidebarMenu?.openSubMenu);
            } else if (!isFromReload) {
                const actualItem = {
                    path: subItem.name,
                    query: subItem.saveQueryParams && this.$route.query,
                };
                this.navigateTo(actualItem);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/variables';

.header-navigation {
    $parent: &;

    &__list {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0;
        padding: 0 0 0 11px;

        &--sub {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 42px;
            background: $gray800;
            color: $color-secondary;

            #{$parent}__item {
                justify-content: center;
                padding: 0;
                margin: 0;
                height: 42px;

                &:hover {
                    #{$parent}__link {
                        color: $color-white;
                    }
                }
            }

            #{$parent}__link {
                width: 164px;
                position: relative;
                color: $color-secondary;
                text-decoration: none;
                font-size: 14px;
                padding: 15px 10px;
                text-align: center;

                &.router-link-active {
                    color: $color-white;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 3px;
                        left: 0;
                        height: 3px;
                        width: 100%;
                        background: $color-primary;
                        display: block;
                        border-top-left-radius: 3px;
                        border-top-right-radius: 3px;
                    }
                }
            }
        }
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 15px 10px;
        max-height: 52px;
        margin-top: 4px;
        cursor: pointer;
        font-size: 14px;
        line-height: 18px;

        &:hover {
            color: $color-primary;
        }

        &--active {
            background: $gray800;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            &:hover {
                color: $color-white;
            }

            #{$parent}__icon {
                margin-right: 6px;
                color: $color-primary;
            }

            #{$parent}__title {
                display: block;
            }
        }
    }

    &__item-link {
        display: flex;
        align-items: center;
        color: $color-white;
        text-decoration: none;
    }

    &__title {
        display: none;
    }
}
</style>
