<template>
  <div class="content dashboard">
    <BRow
      class="bd-content__header h1 mb-3 mt-2 grid"
    >
      <BCol
        class="dashboard__header grid"
      >
        <div
          class="grid__col grid__col--no-grow dashboard-title"
        >
          {{ dashboard.name }}
        </div>

        <div class="grid__col min-w-175">
          <BButton
            v-if="!editState"
            variant="outline-primary"
            size="sm"
            class="dashboard__edit-btn"
            @click="setEditStateTo(true)"
          >
            Edit
          </BButton>

          <template
            v-if="editState"
          >
            <BButton
              variant="outline-secondary"
              size="sm"
              @click="onCancelChanges()"
            >
              Cancel
            </BButton>

            <BButton
              v-if="canBeSaved"
              variant="outline-success"
              size="sm"
              @click="onSaveDashboard()"
            >
              Save dashboard
            </BButton>
          </template>
        </div>
      </BCol>
      <BCol
        :style="{ visibility: editState ? 'visible' : 'hidden' }"
        class="grid__col--no-grow"
      >
        <BButton
          variant="outline-primary"
          size="sm"
          class="w-max-content"
          @click="onAddFilters()"
        >
          Filters setup
        </BButton>
      </BCol>
    </BRow>

    <KmtxFilterBuilder
      v-if="filtersList && filtersList.length && initialFiltersList"
      :key="dashboardId"
      ref="filters"
      :filters="filtersList"
      :disabled="editState"
      class="mb-4"
    />
    <DashboardRows
      :dashboard="dashboard"
    />

    <BSidebar
      id="widget-sidebar"
      ref="sidebar"
      :visible="sidebarVisible"
      right
      no-header-close
      shadow
    >
      <div class="widget-sidebar__header px-3 mb-2">
        <div class="d-flex flex-fill">
          <button
            class="widget-sidebar--close-btn btn btn-sm"
            @click="onCloseSideBar()"
          >
            <Icon
              image="close.svg"
              :size="{ width: '16px', height: '16px' }"
              class="menu-list__tree-icon"
              label="Close"
            />
          </button>
        </div>
        <h2 class="mb-0">
          {{ sidebarTitle }}
        </h2>
      </div>
      <WidgetPutter
        v-if="widgetBarVisible"
        ref="widgetPutter"
        :filters-list="filtersList"
        :dashboard-id="dashboardId"
        @update-error="toast('Widget setup', $event.error, 'danger')"
        @update="toast('Widget setup', $event.message)"
      />
      <FiltersPutter
        v-if="filtersBarVisible"
        ref="filtersPutter"
        :dashboard-id="dashboardId"
        :items="dashboard.configuration.filters_list || []"
        @save-filters="onSaveFilters($event)"
      />
    </BSidebar>
  </div>
</template>

<script>
import clone from 'rfdc';
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import DashboardRows from './DashboardRows.vue';
import WidgetPutter from './widget-putter/index.vue';
import FiltersPutter from './filters-putter/index.vue';
import Icons from '../IconsComponent.vue';
import { leaveModalOptions } from '../../helper';
import kmtxFilterBuilder from '../new-filters/builder/index.vue';

export default {
  name: 'Dashboard',
  components: {
    DashboardRows,
    WidgetPutter,
    FiltersPutter,
    Icon: Icons,
    'kmtx-filter-builder': kmtxFilterBuilder,
  },
  beforeRouteLeave(to, from, next) {
    if (this.editState && to?.name !== 'login') {
      this.beforeRouteLeaveDialog().then((doLeave) => next(doLeave));
    } else {
      this.onRouteUpdate();
      next();
    }
  },
  data() {
    return {
      initialWidgets: null,
      initialFiltersList: null,
    };
  },
  computed: {
    ...mapState('dataMarts', [
      'selectedWidgetBody',
      'editState',
      'widgetBarVisible',
      'filtersBarVisible',
      'widgets',
      'widgetFormDirty',
      'filtersFormDirty',
    ]),
    ...mapGetters('dataMarts', ['canBeSaved', 'getDashboardById']),
    sidebarVisible: {
      get() {
        return this.widgetBarVisible || this.filtersBarVisible;
      },
    },
    sidebarTitle() {
      if (this.widgetBarVisible) {
        return this.selectedWidgetBody.id ? `Edit ${this.selectedWidgetBody.name}` : 'New widget';
      } if (this.filtersBarVisible) {
        return 'Filters setup';
      }
      return '';
    },
    dashboard() {
      return this.getDashboardById(this.dashboardId) || { name: '' };
    },
    dashboardId() {
      return +this.$route.name.match(/\d+$/)[0];
    },
    filtersList() {
      const dashboardFilters = this.dashboard?.configuration?.filters_list || [];
      const filters = [...dashboardFilters];
      const exportFilter = {
        data: [],
        filter_name: 'export',
        filter_value: 'export',
      };
      this.dashboard?.widgets?.forEach((widget) => {
        if (widget.configuration?.flags?.exportable) {
          exportFilter.data.push({ name: widget.name, id: widget.id });
        }
      });
      if (exportFilter.data.length) {
        filters.push(exportFilter);
      }
      return filters;
    },
  },
  watch: {
    dashboardId: {
      handler: 'getDashboard',
      immediate: true,
    },
  },
  methods: {
    ...mapActions('dataMarts', ['editDashboard', 'fetchDashboard']),
    ...mapMutations('dataMarts', [
      'setEditStateTo',
      'setWidgetBarVisibleTo',
      'setFiltersBarVisibleTo',
      'clearWidgets',
      'clearSelectedWidget',
      'setWidgets',
    ]),
    getDashboard(id = this.dashboardId) {
      const data = {
        id,
        params: { expand: 'widgets,roles,owner' },
      };
      return this.fetchDashboard(data).then((data) => {
        this.setWidgets(data);
        this.initialWidgets = clone()(this.widgets);
        this.initialFiltersList = clone()(this.filtersList);
      }).catch((error) => {
        if (!error.cancelReason) throw error;
      });
    },
    onSaveDashboard() {
      const widgets = [];
      const rows = [];
      const dashboard = clone()(this.dashboard);

      if (this.widgets?.length) {
        this.widgets.forEach((row) => {
          rows.push({ cols: row.length });
          row = row.filter((col) => col.id);
          widgets.push(...row);
        });
      }

      dashboard.widgets = widgets;
      dashboard.configuration.row = rows;
      if (dashboard.configuration.filters_list?.length) {
        dashboard.configuration.filters_list.forEach((filter) => {
          if (filter.filter_fields) {
            filter.filter_fields = filter.filter_fields.filter((field) => dashboard.widgets.find((widget) => widget.id === field.widgetId));
          }
        });
      }

      const params = { expand: 'widgets,roles,owner' };
      this.editDashboard({ dashboard, params }).then((data) => {
        this.setWidgets(data);
      });
      this.clearStateTogglers();
    },
    onCancelChanges() {
      const doCancel = () => {
        this.clearStateTogglers();
        this.getDashboard(this.dashboardId).catch((error) => {
          if (!error.cancelReason) {
            this.toast(error, 'Something went wrong', 'danger');
          }
        });
      };
      if (JSON.stringify(this.widgets) === JSON.stringify(this.initialWidgets)
                && JSON.stringify(this.filtersList) === JSON.stringify(this.initialFiltersList)) {
        doCancel();
      } else {
        this.leaveModal().then((doLeave) => {
          if (doLeave) {
            doCancel();
          }
        });
      }
    },
    onAddFilters() {
      if (this.widgetBarVisible) {
        this.$bvModal.msgBoxConfirm('You can\'t undo this operation', leaveModalOptions()).then((doLeave) => {
          if (doLeave) {
            this.setWidgetBarVisibleTo(false);
            this.clearSelectedWidget();
            this.setFiltersBarVisibleTo(true);
          }
        });
      } else {
        this.setFiltersBarVisibleTo(true);
      }
    },
    beforeRouteLeaveDialog() {
      return this.leaveModal().then((doLeave) => {
        if (doLeave) {
          this.onRouteUpdate();
          return true;
        }
        return false;
      });
    },
    onRouteUpdate() {
      this.clearStateTogglers();
      this.clearWidgets();
    },
    clearStateTogglers() {
      this.setWidgetBarVisibleTo(false);
      this.setFiltersBarVisibleTo(false);
      this.setEditStateTo(false);
      this.clearSelectedWidget();
    },
    onCloseSideBar() {
      const closeSidebar = () => (this.widgetBarVisible ? this.setWidgetBarVisibleTo(false) : this.setFiltersBarVisibleTo(false));
      if (this.widgetFormDirty || this.filtersFormDirty) {
        this.leaveModal().then((doLeave) => {
          if (doLeave) {
            closeSidebar();
          }
        });
      } else {
        closeSidebar();
      }
    },
    onSaveFilters() {
      this.setFiltersBarVisibleTo(false);
      this.toast('Filters Setup', 'Applied successfully!');
    },
    leaveModal() {
      const bodyText = this.$createElement('div', ['You can\'t undo this operation']);
      return this.$bvModal.msgBoxConfirm(bodyText, leaveModalOptions());
    },
    toast(title, message, variant = 'success') {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
    .dashboard-title {
        margin-right: 20px;
        word-break: break-all;
    }

    .min-w-175 {
        min-width: 175px;
    }

    .max-w-600 {
        max-width: 600px;
    }

    .dashboard {
        &__header {
            margin: 0;
        }
    }

    .widget-sidebar__header {
        display: flex;
        align-items: center;
    }

    .widget-sidebar--close-btn {
        margin-left: -8px;
    }

    .w-max-content {
        width: max-content;
    }
</style>
