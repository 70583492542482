import { MultiGrant } from './interfaces';

export type StatusName = 'deactivated' | 'pending' | 'active' | 'notActivated' | 'inactive' | 'archived' | 'draft' | '';

export function getStatus(value: string | number): StatusName {
    const status = {
        0: 'inactive',
        1: 'inactive',
        2: 'pending',
        3: 'active',
        4: 'archived',
        5: 'deactivated',
        6: 'notActivated',
    };
    return status[value] || '';
}

export type StatusIconName = 'stop' | 'pause' | 'checkmark' | 'arrow-down' | 'close' | '';

export function getStatusIcon(value: string | number): StatusIconName {
    const icon = {
        0: 'stop',
        1: 'stop',
        2: 'pause',
        3: 'checkmark',
        4: 'arrow-down',
        5: 'close',
        6: 'close',
    };
    return icon[value] || '';
}

export function returnDetailsRow(row: unknown[]): unknown[] {
    return row.slice(1);
}

type StatusObject = {
    value: string | number;
    name: string;
};

export function formattedStatusName(list: StatusObject[], value: string | number): string | undefined {
    if (value === 0 || value === '0' || value) {
        return list.find((status) => status.value === value)?.name;
    }
    return '';
}

type StatusIconNameDatamart = 'pause' | 'checkmark' | 'close';

export function getStatusIconDataMart(value: string | number): StatusIconNameDatamart {
    const icon = {
        1: 'checkmark',
        2: 'pause',
        3: 'pause',
        4: 'checkmark',
        5: 'close',
    };

    return icon[value] || 'stop';
}

type StatusNameDataMart = 'created' | 'pending' | 'processing' | 'active' | 'failed';
export function getStatusNameDataMart(value: string | number): StatusNameDataMart {
    const status = {
        1: 'created',
        2: 'pending',
        3: 'processing',
        4: 'active',
        5: 'failed',
    };
    return status[value] || '';
}

export function getNewUniqGrants(initGrant: MultiGrant[], newGrant: MultiGrant[]): { grants: MultiGrant[]; hasDuplicate: boolean } {
    let hasDuplicate = false;
    const grants = newGrant
        .map((grant) => {
            const existGrant = initGrant.find(
                (item) =>
                    (item.level === 'global' && grant.level === 'global') ||
                    (item.level === grant.level && item.database === grant.database && item.table === grant.table)
            );
            if (existGrant) {
                return {
                    ...existGrant,
                    types: grant.types.filter((type) => {
                        if (existGrant.types.includes(type)) {
                            hasDuplicate = true;
                            return false;
                        }
                        return true;
                    }),
                };
            }
            return grant;
        })
        .filter((grant) => grant.types.length > 0);

    return { grants, hasDuplicate };
}
