<template>
    <div class="content">
        <KmtxFilterBuilder
            ref="filters"
            :filters="filtersOptions"
            @datepicker-change="onDateChange"
            @domainlist-change="fillData"
            @domainlist-init="fillData"
        />

        <div>
            <BRow>
                <BCol
                    md="2"
                    class="grid-margin stretch-card"
                >
                    <div class="card card-statistics">
                        <div class="card-body">
                            <div class="clearfix">
                                <div class="float-left">
                                    <p>AWS</p>
                                </div>
                                <div class="float-right">
                                    <p class="mb-0 text-right">
                                        Total Orders
                                    </p>
                                    <div class="fluid-container  text-right">
                                        <h3 class="font-weight-medium text-right mb-0">
                                            {{ filters.bigNumberFormat(counters.total.all) }}
                                        </h3>
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="text-right">
                                <table class="float-right">
                                    <tr>
                                        <td>
                                            web
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.total.desktop) }}</b>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            web (mobile)
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.total.mobile_web) }}</b>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            app
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.total.mobile_app) }}</b>
                                        </td>
                                        <td></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </BCol>
                <BCol
                    md="2"
                    class="grid-margin stretch-card"
                >
                    <div class="card card-statistics">
                        <div class="card-body">
                            <div class="clearfix">
                                <div class="float-left">
                                    <p>AWS</p>
                                </div>
                                <div class="float-right">
                                    <p class="mb-0 text-right">
                                        AWS Widget Orders
                                    </p>
                                    <div class="fluid-container text-right">
                                        <h3 class="font-weight-medium text-right mb-0">
                                            {{ filters.bigNumberFormat(counters.aws.all) }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="text-right">
                                <table class="float-right">
                                    <tr>
                                        <td>
                                            web
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.aws.desktop) }}</b>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            web (mobile)
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.aws.mobile_web) }}</b>
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            app
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.aws.mobile_app) }}</b>
                                        </td>
                                        <td></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </BCol>
                <BCol
                    md="2"
                    class="grid-margin stretch-card"
                >
                    <div class="card card-statistics">
                        <div class="card-body">
                            <div class="clearfix">
                                <div class="float-left">
                                    <p>AWS</p>
                                </div>
                                <div class="float-right">
                                    <p class="mb-0 text-right">
                                        Support Sales
                                    </p>
                                    <div class="fluid-container  text-right">
                                        <h3 class="font-weight-medium text-right mb-0">
                                            {{ filters.bigNumberFormat(counters.support.all) }}
                                        </h3>
                                        <span class="text-primary">
                                            {{ Number(counters.support.all * 100 / counters.aws.all).toFixed(2) }}%
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="text-right">
                                <table class="float-right">
                                    <tr>
                                        <td>
                                            web
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.support.desktop) }}</b>
                                        </td>
                                        <td>
                                          <span class="text-info">
                                            {{ (Number(counters.support.desktop * 100 / counters.total.desktop)).toFixed(2) }}%
                                          </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            web (mobile)
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.support.mobile_web) }}</b>
                                        </td>
                                        <td>
                                          <span class="text-info">
                                            {{ (Number(counters.support.mobile_web * 100 / counters.total.mobile_web)).toFixed(2) }}%
                                          </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            app
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.support.mobile_app) }}</b>
                                        </td>
                                        <td>
                                          <span class="text-info">
                                            {{ (Number(counters.support.mobile_app * 100 / counters.total.mobile_app)).toFixed(2) }}%
                                          </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </BCol>
                <BCol
                    md="2"
                    class="grid-margin stretch-card"
                >
                    <div class="card card-statistics">
                        <div class="card-body">
                            <div class="clearfix">
                                <div class="float-left">
                                    <p>AWS</p>
                                </div>
                                <div class="float-right">
                                    <p class="mb-0 text-right">
                                        External Sales
                                    </p>
                                    <div class="fluid-container  text-right">
                                        <h3 class="font-weight-medium text-right mb-0">
                                            {{ filters.bigNumberFormat(counters.external.all) }}
                                        </h3>
                                        <span class="text-primary">
                                          {{ Number(Math.abs(counters.external.all * 100 / counters.total.all).toFixed(2)) }}%
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="text-right">
                                <table class="float-right">
                                    <tr>
                                        <td>
                                            Amazon
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.external.amazon) }}</b>
                                        </td>
                                        <td>
                                          <span class="text-info">
                                            {{ Number(Math.abs(counters.external.amazon * 100 / counters.external.all).toFixed(2)) }}%
                                          </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Ebay
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.external.ebay) }}</b>
                                        </td>
                                        <td>
                                          <span class="text-info">
                                            {{ Number(Math.abs(counters.external.ebay * 100 / counters.external.all).toFixed(2)) }}%
                                          </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Other
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.external.other) }}</b>
                                        </td>
                                        <td>
                                          <span class="text-info">
                                            {{ Number(Math.abs(counters.external.other * 100 / counters.external.all).toFixed(2)) }}%
                                          </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </BCol>
                <BCol
                    md="2"
                    class="grid-margin stretch-card"
                >
                    <div class="card card-statistics">
                        <div class="card-body">
                            <div class="clearfix">
                                <div class="float-left">
                                    <p>KMTX</p>
                                </div>
                                <div class="float-right">
                                    <p class="mb-0 text-right">
                                        Total Orders
                                    </p>
                                    <div class="fluid-container  text-right">
                                        <h3 class="font-weight-medium text-right mb-0">
                                            {{ filters.bigNumberFormat(counters.kmtx.all) }}
                                        </h3>
                                        <span
                                            :class="{
                                                'text-danger': counters.kmtx.all < counters.total.all,
                                                'text-success': counters.kmtx.all > counters.total.all,
                                              }"
                                        >
                                              {{ kmtxTotalDiff }}%
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="text-right">
                                <table class="float-right">
                                    <tr>
                                        <td>
                                            web
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.kmtx.desktop) }}</b>
                                        </td>
                                        <td>
                                            <span
                                            :class="{
                                              'text-danger': counters.kmtx.desktop < counters.total.desktop,
                                              'text-success': counters.kmtx.desktop > counters.total.desktop,
                                            }"
                                            >
                                                {{ kmtxDesktopDiff }}%
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            web mobile
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.kmtx.mobile_web) }}</b>
                                        </td>
                                        <td>
                                            <span
                                                :class="{
                                                  'text-danger': counters.kmtx.desktop < counters.total.desktop,
                                                  'text-success': counters.kmtx.desktop > counters.total.desktop,
                                                }"
                                            >
                                                {{ kmtxMobileWebDiff }}%
                                          </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            app
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.kmtx.mobile_app) }}</b>
                                        </td>
                                        <td>
                                            <span
                                                :class="{
                                                  'text-danger': counters.kmtx.desktop < counters.total.desktop,
                                                  'text-success': counters.kmtx.desktop > counters.total.desktop,
                                                }"
                                            >
                                                {{ kmtxMobileAppDiff }}%
                                          </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </BCol>
                <BCol
                    md="2"
                    class="grid-margin stretch-card"
                >
                    <div class="card card-statistics">
                        <div class="card-body">
                            <div class="clearfix">
                                <div class="float-left">
                                    <p>KMTX</p>
                                </div>
                                <div class="float-right">
                                    <p class="mb-0 text-right">
                                        Total 0-Cookie
                                    </p>
                                    <div class="fluid-container text-right">
                                        <h3 class="font-weight-medium text-right mb-0">
                                            {{ filters.bigNumberFormat(counters.zeroDay.all) }}
                                        </h3>
                                        <span class="text-info">
                                          {{ zeroDaysTotalDiff }}%
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="text-right">
                                <table class="float-right">
                                    <tr>
                                        <td>
                                            web
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.zeroDay.desktop) }}</b>
                                        </td>
                                        <td>
                                          <span class="text-info">
                                            {{ zeroDaysDesktopDiff }}%
                                          </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            web mobile
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.zeroDay.mobile_web) }}</b>
                                        </td>
                                        <td>
                                          <span class="text-info">
                                            {{ zeroDaysMobileWebDiff }}%
                                          </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            app
                                        </td>
                                        <td>
                                            <b>{{ filters.bigNumberFormat(counters.zeroDay.mobile_app) }}</b>
                                        </td>
                                        <td>
                                            <span class="text-info">
                                                {{ zeroDaysMobileAppDiff }}%
                                          </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </BCol>
            </BRow>
            <BRow class="mb-4 mt-4">
                <BCol md="12">
                    <TableComponent
                        :id="tableId"
                        responsive
                        hover
                        :per-page="pagination.per_page"
                        :items="items"
                        :fields="fields"
                    >
                        <template #cell(support_percent)="data">
                            {{
                                Number(data.item.support * 100 / data.item.nm)
                                    .toFixed(2)
                            }}%
                        </template>

                        <template #cell(kmtx_percent)="data">
                            {{
                                Number(Math.abs(data.item.nm - data.item.support - data.item.kmtx) * 100 / (data.item.nm - data.item.support))
                                    .toFixed(2)
                            }}%
                        </template>
                    </TableComponent>
                </BCol>
            </BRow>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// eslint-disable-next-line import/no-cycle
import AuditService from '../services/Audit';
import kmtxFilterBuilder from './filters/builder/index.vue';
import TableComponent from './TableComponent.vue';

export default {
    name: 'AuditComponent',
    components: {
        kmtxFilterBuilder,
        TableComponent,
    },
    data() {
        return {
            filtersOptions: [
                {
                    filter: 'datepicker',
                    label: 'Dates',
                },
                {
                    filter: 'domainlist',
                    label: 'Domain/Label',
                },
            ],
            counters: {
                total: {
                    all: 0,
                    desktop: 0,
                    mobile_app: 0,
                    mobile_web: 0,
                },
                aws: {
                    all: 0,
                    desktop: 0,
                    mobile_app: 0,
                    mobile_web: 0,
                },
                support: {
                    all: 0,
                    desktop: 0,
                    mobile_app: 0,
                    mobile_web: 0,
                },
                external: {
                    all: 0,
                    amazon: 0,
                    ebay: 0,
                    other: 0,
                },
                kmtx: {
                    all: 0,
                    desktop: 0,
                    mobile_app: 0,
                    mobile_web: 0,
                },
                zeroDay: {
                    all: 0,
                    desktop: 0,
                    mobile_app: 0,
                    mobile_web: 0,
                },
            },
            fields: [
                {
                    key: 'skin',
                    label: 'Skin',
                    sortable: true,
                },
                {
                    key: 'nm',
                    label: 'AWS',
                    sortable: true,
                },
                {
                    key: 'support',
                    label: 'Support',
                    sortable: true,
                },
                {
                    key: 'support_percent',
                    label: 'Support %',
                },
                {
                    key: 'kmtx',
                    label: 'KMTX',
                    sortable: true,
                },
                {
                    key: 'kmtx_percent',
                    label: 'KMTX %',
                },

            ],
            items: [],
            pagination: {
                per_page: 30,
            },
            tableId: 'audit-orders-table',
        };
    },
    computed: {
        ...mapGetters('filters', {
            getCurrentDomain: 'getCurrentDomain',
            getDateForParams: 'getDateForParams',
        }),
        kmtxDesktopDiff() {
            // suppose all external orders are desktop web
            const diff = Math.abs(this.counters.total.desktop - this.counters.support.desktop - this.counters.external.all - this.counters.kmtx.desktop);
            return (Number(diff * 100 / (this.counters.total.desktop - this.counters.support.desktop))).toFixed(2);
        },
        kmtxMobileWebDiff() {
            const diff = Math.abs(this.counters.total.mobile_web - this.counters.support.mobile_web - this.counters.kmtx.mobile_web);
            return (Number(diff * 100 / (this.counters.total.mobile_web - this.counters.support.mobile_web))).toFixed(2);
        },
        kmtxMobileAppDiff() {
            const diff = Math.abs(this.counters.total.mobile_app - this.counters.support.mobile_app - this.counters.kmtx.mobile_app);
            return (Number(diff * 100 / (this.counters.total.mobile_app - this.counters.support.mobile_app))).toFixed(2);
        },
        kmtxTotalDiff() {
            const cleanOrders = Math.abs(this.counters.total.all - this.counters.support.all - this.counters.external.all);
            return (Number((cleanOrders - this.counters.kmtx.all) * 100 / (cleanOrders))).toFixed(2);
        },
        zeroDaysDesktopDiff() {
            return (Number(this.counters.zeroDay.desktop * 100 / (this.counters.kmtx.desktop))).toFixed(2);
        },
        zeroDaysMobileWebDiff() {
            return (Number(this.counters.zeroDay.mobile_web * 100 / (this.counters.kmtx.mobile_web))).toFixed(2);
        },
        zeroDaysMobileAppDiff() {
            return (Number(this.counters.zeroDay.mobile_app * 100 / (this.counters.kmtx.mobile_app))).toFixed(2);
        },
        zeroDaysTotalDiff() {
            return (Number(this.counters.zeroDay.all * 100 / (this.counters.kmtx.all))).toFixed(2);
        },
    },
    methods: {
        onDateChange() {
            this.fillData();
        },
        fillData() {
            const showLoader = true;
            const params = Object.create(null);
            params.label_source = [this.getCurrentDomain.id];
            params.label_type = this.getCurrentDomain.labelType;

            params.dateFrom = this.getDateForParams.dateFrom;
            params.dateTo = this.getDateForParams.dateTo;

            AuditService.getTotalOrders(params, { showLoader })
                .then((resp) => {
                    this.counters = resp.data;
                    this.items = resp.data.items;
                })
                .catch((error) => {
                    if (!error.cancelReason) throw error;
                });
        },
    },
};
</script>
