import RoutingViewContainer from '@/components/RoutingViewContainer.vue';
import store from '@/store';
import LocalStorage from "@/services/LocalStorage";
import { ACTIVITY_LOG_PAGES } from './services/constants';

const activityLogPermissions = ACTIVITY_LOG_PAGES.map(({ permission }) => permission);
const activityLogBrowserTitle = 'Activity log - Data Platform';

export default {
  path: '/activity-log',
  component: RoutingViewContainer,
  meta: {
    permission: activityLogPermissions,
    allowForMaster: true,
  },
  name: 'activity-log',
  redirect: () => {
    const hasPermission = ACTIVITY_LOG_PAGES.find((page) => store.getters['auth/hasAnyPermissionToService'](page.permission));
    return { path: hasPermission?.route || 'welcome' };
  },
  children: [
    {
      path: 'iam-accounts',
      component: () => import(/* webpackChunkName: "[LogIAMAccounts]" */ '@/modules/activity-log/pages/iam/ActivityLogAccountsIAM.vue'),
      name: 'al-iam-accounts',
      meta: {
        permission: 'iam:accounts:tab',
        allowForMaster: true,
        browserTitle: `IAM Accounts - ${activityLogBrowserTitle}`,
      },
    },
    {
      path: 'iam-users',
      component: () => import(/* webpackChunkName: "[LogIAMUsers]" */ '@/modules/activity-log/pages/iam/ActivityLogUsersIAM.vue'),
      name: 'al-iam-users',
      meta: {
        permission: 'iam:accounts:tab',
        allowForMaster: true,
        browserTitle: `IAM Users - ${activityLogBrowserTitle}`,
      },
    },
    {
      path: 'iam-roles',
      component: () => import(/* webpackChunkName: "[LogIAMRoles]" */ '@/modules/activity-log/pages/iam/ActivityLogRolesIAM.vue'),
      name: 'al-iam-roles',
      meta: {
        permission: 'iam:accounts:tab',
        allowForMaster: true,
        browserTitle: `IAM Roles - ${activityLogBrowserTitle}`,
      },
    },
    {
      path: 'dsm-connections',
      component: () => import(/* webpackChunkName: "[LogDSMConnections]" */ '@/modules/activity-log/pages/dsm/ActivityLogConnectionsDSM.vue'),
      name: 'al-dsm-connections',
      meta: {
        permission: 'dw:dsm:data-sources',
        browserTitle: `DSM Connections - ${activityLogBrowserTitle}`,
      },
    },
    {
      path: 'dsm-users',
      component: () => import(/* webpackChunkName: "[LogDSMUsers]" */ '@/modules/activity-log/pages/dsm/ActivityLogUsersDSM.vue'),
      name: 'al-dsm-users',
      meta: {
        permission: 'dw:dsm:data-sources',
        browserTitle: `DSM Users - ${activityLogBrowserTitle}`,
      },
    },
    {
      path: 'dsm-services',
      component: () => import(/* webpackChunkName: "[LogDSMServices]" */ '@/modules/activity-log/pages/dsm/ActivityLogServicesDSM.vue'),
      name: 'al-dsm-services',
      meta: {
        permission: 'dw:dsm:data-sources',
        browserTitle: `DSM Services - ${activityLogBrowserTitle}`,
      },
    },
    {
      path: 'dsm-access-requests',
      component: () => import(/* webpackChunkName: "[LogAccessRequests]" */ '@/modules/activity-log/pages/dsm/ActivityLogAccessRequests.vue'),
      name: 'al-dsm-access-requests',
      meta: {
        permission: 'dw:dsm:data-sources',
        allowForMaster: true,
        browserTitle: `DSM Access requests - ${activityLogBrowserTitle}`,
      },
    },

    // TODO add without LocalStorage when backend will work normal (r.kravtsov) 2 of 2 part
    ...(LocalStorage.get('activity-log-data-marts') ? [{
      path: 'dsm-data-marts',
      component: () => import(/* webpackChunkName: "[LogDSMDataMarts]" */ '@/modules/activity-log/pages/dsm/ActivityLogDataMartsDSM.vue'),
      name: 'al-dsm-data-marts',
      meta: {
        permission: 'dw:dsm:data-sources',
        browserTitle: `DSM Data marts - ${activityLogBrowserTitle}`,
      },
    }] : []),
  ],
};
