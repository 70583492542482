var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.KFlexContainer,{staticClass:"grow-1 mt-3 ml-2 mr-2",attrs:{"direction":"column"}},[_c(_setup.KFlexContainer,{staticClass:"w-100 mb-2",attrs:{"justifyContent":"space-between"}},[_c(_setup.KFlexItem,{staticClass:"ml-auto",attrs:{"alignSelf":"center"}},[(_setup.pagination.total)?_c(_setup.PaginationTotal,{attrs:{"value":+_setup.pagination.total}}):_vm._e(),_c(_setup.KPagination,{attrs:{"perPage":_setup.pagination.limit,"page":_setup.pagination.page,"pages":_setup.pagination.pages || 1,"perPageOptions":_setup.perPageOptions},on:{"perPageUpdated":({ name }) => _setup.onPerPageUpdated(name),"submit":_setup.onChangePaginationPage}})],1)],1),_c('div',{staticClass:"ab-test-table__wrapper"},[_c(_setup.TableComponent,{attrs:{"fields":_setup.tableFields,"items":_setup.testList,"empty-text":"There are no records to show"},on:{"row-clicked":_setup.openTest},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.width + '%' })})})}},{key:"cell(status)",fn:function(row){return [_c(_setup.KLabel,{attrs:{"text":row.item.status.name,"status":_setup.getStatus(row.item.status.value),"icon":_setup.getStatusIcon(row.item.status.value),"showText":""}})]}},{key:"cell(id)",fn:function(row){return [_c('RouterLink',{attrs:{"to":{
            path: '/cockpit/' + row.item.id,
          }}},[_c(_setup.KText,[_vm._v(_vm._s(row.item.id))])],1)]}},{key:"cell(markets)",fn:function(row){return [_c(_setup.KFlexContainer,{staticClass:"chips-container"},_vm._l((Object.keys(row.item.algo_setting.country)),function(item,key){return _c(_setup.KFlexItem,{key:key},[_c(_setup.KChips,{attrs:{"label":item}})],1)}),1)]}},{key:"cell(creator)",fn:function(row){return [(_setup.iamUserList.length)?_c(_setup.LinkWithPermission,{attrs:{"linkTo":{
            to: {
              path: '/iam/users/user/' + row.item.creator_id,
            },
          }}},[_c(_setup.KButton,{attrs:{"icon":"sharing-solid","level":"link","label":_setup.getIAMUserName(row.item.creator_id)}})],1):_vm._e()]}},{key:"cell(date)",fn:function(row){return [([1, 3, 4].includes(row.item.status.value))?_c(_setup.KText,[_vm._v(" "+_vm._s(_setup.getFormattedDateOrDashes(row.item.created_at, false))+" - "+_vm._s(_setup.getFormattedDateOrDashes(row.item.end_date, false))+" ")]):_vm._e()]}},{key:"cell(actions)",fn:function(row){return [_c(_setup.KFlexContainer,{attrs:{"wrap":"nowrap"}},[(row.item.status.value === 3)?_c(_setup.KIconButton,{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"ml-3",attrs:{"icon":"arrow-clockwise","title":"Launch"},on:{"click":function($event){return _setup.launchABTest(row.item.id)}}}):_vm._e(),(row.item.status.value === 1)?_c(_setup.KIconButton,{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"ml-3",attrs:{"icon":"cross","title":"Stop"},on:{"click":function($event){return _setup.stopABTest(row.item.id)}}}):_vm._e(),([3, 5].includes(row.item.status.value))?_c(_setup.KIconButton,{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"ml-3",attrs:{"icon":"delete-outline","title":"Delete"},on:{"click":function($event){return _setup.openDeleteModal(row.item.id)}}}):_vm._e()],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }