import RoutingViewContainer from '@/components/RoutingViewContainer.vue';
import LabelsList from './pages/LabelsList.vue';

export default {
    path: `/labels`,
    component: RoutingViewContainer,
    name: 'labels',
    redirect: { name: 'labels-list' },
    children: [
        {
            path: '',
            component: LabelsList,
            name: 'labels-list',
            meta: {
                browserTitle: 'Label Management - Data Platform',
                masterAccountOnly: true,
                allowForMaster: true,
            },
        },
    ],
};
