import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

const TrackingConfigPage = () => import(/* webpackChunkName: '[ConfigPage]' */ './pages/config/TrackingConfigPage.vue');
const LabelCategoriesPage = () => import(/* webpackChunkName: '[ConfigPage]' */ './pages/label-categories/LabelCategoriesPage.vue');
const TrackingLabelsPage = () => import(/* webpackChunkName: '[ConfigPage]' */ './pages/labels/TrackingLabelsPage.vue');

function setQueryParamsForLabels(to, from, next) {
    const defaultQueryParams = {
        search_value: '',
        per_page: 25,
        page: 1,
        category: '',
        state: 1,
    };

    const queryParamsKeys = Object.keys(to.query);

    if (Object.keys(defaultQueryParams).every((key) => queryParamsKeys.includes(key))) {
        next();

        return;
    }

    if (queryParamsKeys.length) {
        next({
            name: 'tracking-labels',
            query: {
                ...defaultQueryParams,
                ...to.query,
            },
        });

        return;
    }

    next({
        name: 'tracking-labels',
        query: { ...defaultQueryParams },
    });
}

export default {
    path: '/tracking/settings',
    name: 'tracking-settings',
    component: RoutingViewContainer,
    redirect: { name: 'tracking-config' },
    children: [
        {
            path: 'config',
            name: 'tracking-config',
            component: TrackingConfigPage,
            meta: {
                header: { title: 'Tracking Сonfig' },
                permission: 'tracking:settings:config',
                browserTitle: 'Tracking Settings - Settings - Tracking',
            },
        },
        {
            path: 'label-categories',
            name: 'label-categories',
            component: LabelCategoriesPage,
            meta: {
                header: { title: 'Label Сategories' },
                permission: 'tracking:settings:label-categories',
                browserTitle: 'Tracking Label categories - Settings - Tracking',
            },
        },
        {
            path: 'labels',
            name: 'tracking-labels',
            component: TrackingLabelsPage,
            beforeEnter: setQueryParamsForLabels,
            meta: {
                permission: 'tracking:settings:label-categories',
                browserTitle: 'Tracking Label - Settings - Tracking',
            },
        },
    ],
};
