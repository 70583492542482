export default {
    'Tracking': {
        name: 'Tracking',
        accessPerson: {
            name: 'Pavlo Alekseiev',
            slackId: 'U01QRN5EQEQ'
        },
        description:
            'Analytical platform for capturing user behavior insights from WEB and APP and enabling data-driven business decision-making. Provides a fast pace markup service for tracking behavioral data.',
    },
    'Marketing Dashboards': {
        name: 'Marketing Dashboards',
        accessPerson: {
            name: 'Pavlo Alekseiev',
            slackId: 'U01QRN5EQEQ'
        },
        description: 'Dashboards with statistics:',
        list: [
            {
                title: 'Carts & Orders',
                description: "user's sessions with their adding of items to the cart, their orders of products.",
            },
            {
                title: 'Traffic & Search',
                description: "user's visits and search queries on Autodoc websites.",
            },
            {
                title: 'Browser statistics',
                description: "user's sessions and visits to Autodoc websites distributed by the web browsers used.",
            },
        ],
    },
    'Marketing Tools': {
        name: 'Marketing Tools',
        accessPerson: {
            name: 'Dmytro Paiareli',
            slackId: 'U01KNBCPJGY'
        },
        description: 'Tools that facilitate the work of marketers and analytics:',
        list: [
            {
                title: 'Route/Skin to URL',
                description: "allows finding all pages with specified routes and skins.",
            },
            {
                title: 'URL to Route/Skin',
                description: "allows determining the route and skin of a page on Autodoc websites based on its URL.",
            },
        ],
    },
    'Service Tools': {
        name: 'Service Tools',
        accessPerson: {
            name: 'Dmytro Paiareli',
            slackId: 'U01KNBCPJGY'
        },
        description: 'Dashboards with statistics:',
        list: [
            {
                title: 'Audit',
                description: "Orders in AWS and Kilometrix, Events, Monitoring of events, Monitoring of autotest on Autodoc websites. ",
            },
            {
                title: 'Scoring',
                description: "Activities of blocked users on Autodoc websites, Lists of blocked users within certain criterias, Settings of blocked IPs which traffic will not be included in Autodoc website traffic statistics.  ",
            },
        ],
    },
    '3-rd Party Services': {
        name: '3-rd Party Services',
        accessPerson: {
            name: 'Dmytro Paiareli',
            slackId: 'U01KNBCPJGY'
        },
        description: 'Tool for managing connections to external services (e.g., Google Analytics, YouTube). Allows data retrieval without disrupting the functionality of external services or exceeding API request limits and storing that data in an internal repository.'
    },
    'IAM': {
        name: 'IAM',
        accessPerson: {
            name: 'Dmytro Paiareli',
            slackId: 'U01KNBCPJGY'
        },
        description: 'Tool for managing users of Data Platform, based on:',
        list: [
            {
                title: 'Users',
                description: "person that uses Data Platform products & tools.",
            },
            {
                title: 'Accounts',
                description: "basic entity that groups users, events etc.",
            },
            {
                title: 'Roles',
                description: "can be assigned to User and contains set of User`s permissions in context of certain Account.",
            },
        ],
    },
    'Schema Monitor': {
        name: 'Schema Monitor',
        accessPerson: {
            name: 'Dmytro Paiareli',
            slackId: 'U01KNBCPJGY'
        },
        description: 'Tool with lists of all available data sources intergrated with tables. It allows to view and manage Data Sources. Allows search for Data Sources, filter by type, configure the number of rows to display per page, and check connection of the Data Source.'
    },
    'Activity Log': {
        name: 'Activity Log',
        accessPerson: {
            name: 'Dmytro Paiareli',
            slackId: 'U01KNBCPJGY'
        },
        description: 'Tool that gather information on the activity event logs generated by Data Platform in a single place to analyse it as a whole. It provides records of activities carried out in Data Platform account such as date when activity accured, user name, activity type and more.'
    },
    'DataWizard': {
        name: 'DataWizard',
        accessPerson: {
            name: 'Dmytro Paiareli',
            slackId: 'U01KNBCPJGY'
        },
        description: 'DataWizard secures Data Sources, controls users and services that can access specific databases, file systems, or cloud storage locations. Allows to manage and monitor Data Source permissions.'
    },
    'Label Management': {
        name: 'Label Management',
        accessPerson: {
            name: 'Dmytro Paiareli',
            slackId: 'U01KNBCPJGY'
        },
    },
    'DDDM Tools': {
        name: 'DDDM Tools',
        accessPerson: {
            name: 'Dmytro Paiareli',
            slackId: 'U01KNBCPJGY'
        },
        description: 'Data Driven Decision Management Tools:',
        list: [
            {
                title: 'Fintech platform',
                description: "is a digital replica of Autodoc that simulates the behavior of all key processes (finances, logistics, procurement of goods, etc.) that impact key financial indicators and their derivatives (currently: revenue, average order value, number of orders, number of paying users, lead traffic, conversion to orders) managed by the Revenue department.",
            },
            {
                title: 'User Profiler Admin Panel',
                description: "the tool allows custom audience creation, data editing, and filtering by specific attributes (e.g., car model, OS, location).",
            },
        ],
    },
    'Validation Rules': {
        name: 'Validation Rules',
        accessPerson: {
            name: 'Dmytro Paiareli',
            slackId: 'U01KNBCPJGY'
        },
        description: 'Tool to create and execute data quality rules and checks. Allows to ensure data accuracy and integrity.',
    }
};
