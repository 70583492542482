<template>
  <BContainer
    fluid
    class="table-container"
    :class="{ 'table-transparent-design': newTransparentDesign }"
  >
    <BRow
      v-if="!nestedComponent"
      class="grid table-container__header"
    >
      <slot name="custom_header" />
      <div v-if="!$slots.custom_header" class="grid__col">
        <h3
          v-if="tableTitle"
          class="mb-2"
        >
          {{ tableTitle }}
        </h3>
        <slot name="subtitle" />
      </div>
      <div
        v-if="showPagination"
        class="table-container__head-controls grid__col grid__col--no-grow grid grid--no-grow"
      >
        <PaginationTotal
          :value="+totalRows"
        />
        <label class="grid__col">Per page:
          <BFormInput
            v-model="pagination.per_page"
            debounce="800"
            min="1"
            max="totalRows"
            step="1"
            type="number"
            size="sm"
            lazy-formatter
            :formatter="perPageFormatter"
            @update="perPageOnUpdate"
          />
        </label>
        <BPagination
          v-model="pagination.current_page"
          :total-rows="totalRows"
          :per-page="rowsPerPage"
          :aria-controls="$attrs.id"
          class="mb-2 table-container__paginator paginator grid__col"
          align="right"
          size="sm"
        >
          <template #first-text>
            <span class="paginator__button paginator__button--first">«</span>
          </template>
          <template #prev-text>
            <span class="paginator__button paginator__button--prev">‹</span>
          </template>
          <template #next-text>
            <span class="paginator__button paginator__button--next">›</span>
          </template>
          <template #last-text>
            <span class="paginator__button paginator__button--last">»</span>
          </template>
          <template #ellipsis-text>
            ...
          </template>
          <template #page="{ page, active }">
            <span
              v-if="active"
              class="paginator__page-link paginator__page-link--active"
            >{{ page }}</span>
            <span
              v-else
              class="paginator__page-link paginator__page-link"
            >{{ page }}</span>
          </template>
        </BPagination>
      </div>
    </BRow>
    <BRow
      no-gutters
      class="table-container__body"
    >
      <BCol>
        <BTable
          ref="table"
          :key="cid"
          v-bind="$attrs"
          :per-page="rowsPerPage"
          :current-page="pagination.current_page"
          class="table-component"
          :class="{
            'table-component--nested-full-size': nestedFullSize,
            'k-scrollbar': customScrollbar,
          }"
          head-variant="kmtx"
          show-empty
          :items="items || dataProvider"
          :sticky-header="stickyHeader"
          :filter-included-fields="fieldsKeys"
          v-on="$listeners"
          @sort-changed="sortChanged"
          @filtered="onFiltered"
        >
          <!--eslint-disable vue/no-deprecated-dollar-scopedslots-api-->
          <template
            v-for="(_, name) in $scopedSlots"
            #[name]="slotData"
          >
            <slot
              :name="name"
              v-bind="slotData"
            />
          </template>

          <template #table-busy>
            <Loader
              :is-loading="true"
            />
          </template>
          <template
            v-if="nestedTableProp || hasNestedTableSlot"
            #cell(kmtx-expand)="row"
          >
            <BButton
              size="sm"
              variant="kmtx"
              @click="row.toggleDetails"
            >
              &gt;
            </BButton>
          </template>
          <template
            #cell(kmtx-more)="row"
          >
            <BDropdown
              no-caret
              variant="outline-light"
              class="table-component__dropdown"
            >
              <template
                v-if="iconComponent"
                #button-content
              >
                <div class="d-flex flex-row align-items-center">
                  <component
                    :is="iconComponent"
                    image="three-dots-vertical.svg"
                    label="Avatar"
                    class="user-menu__icon"
                    :size="{ width: '16px', height: '16px' }"
                  />
                </div>
              </template>
              <BDropdownItem
                v-for="(action, index) of row.item.kmtxMoreActions"
                :key="index"
                class="table-component__dropdown-item"
                @click="$emit(action.event, row.item)"
              >
                <span>
                  {{ action.text }}
                </span>
              </BDropdownItem>
            </BDropdown>
          </template>

          <template
            v-if="nestedTableProp"
            #row-details="row"
          >
            <TableComponent
              v-if="nestedTableData.nestedTableData"
              hover
              nested-component
              :per-page="1000"
              :sticky-header="nestedTableData.stickyHeader"
              :responsive="nestedTableData.responsive"
              :sort-by="nestedTableData.sortBy"
              :sort-desc="nestedTableData.sortDesc"
              :fields="nestedTableData.fields"
              :nested-table-data="nestedTableData.nestedTableData"
              :items="row.item[nestedTableData.property]"
            >
              <template #cell(count)="nestedRow">
                <div v-if="row.item.ratio">
                  <span class="font-weight-bold"> {{ nestedRow.item.ratio }} </span>
                  <span class="text-secondary"> ({{ kFormatter(nestedRow.item.count) }}) </span>
                </div>
                <div v-else>
                  {{ kFormatter(nestedRow.item.count) }}
                </div>
              </template>
            </TableComponent>

            <BTable
              v-else
              primary-key="Index"
              :data-pid="row.item.Index || 0"
              :responsive="nestedTableData.responsive"
              :sticky-header="nestedTableData.stickyHeader"
              :items="row.item[nestedTableData.property]"
              :fields="nestedTableData.fields"
              :sort-by="nestedTableData.sortBy"
              :sort-desc="nestedTableData.sortDesc"
              class="table-component__nested"
              head-variant="kmtx"
              v-on="$listeners"
            >
              <template #cell(count)="nestedRow">
                <div v-if="row.item.ratio">
                  <span class="font-weight-bold"> {{ nestedRow.item.ratio }} </span>
                  <span class="text-secondary"> ({{ kFormatter(nestedRow.item.count) }}) </span>
                </div>
                <div v-else>
                  {{ kFormatter(nestedRow.item.count) }}
                </div>
              </template>
            </BTable>
          </template>
        </BTable>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import Loader from '@/components/Loader.vue';
import PaginationTotal from '@/components/ui/PaginationTotal.vue';

import { kFormatter } from '@/helper';
import EventBus from '../event';

export default {
  name: 'TableComponent',
  components: {
    Loader,
    PaginationTotal,
  },
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      required: false,
      default: null,
    },
    perPage: {
      type: Number,
      required: false,
      default: 15,
    },
    providerSource: {
      type: Function,
      required: false,
      default: () => {},
    },
    providerParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    tableTitle: {
      type: String,
      required: false,
      default: undefined,
    },
    nestedTableData: {
      type: Object,
      default: () => {},
    },
    nestedComponent: {
      type: Boolean,
      default: false,
    },
    hasNestedTableSlot: {
      type: Boolean,
      default: false,
    },
    nestedFullSize: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    customScrollbar: {
      type: Boolean,
      default: false,
    },
    newTransparentDesign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nestedTableProp: false,
      isBusy: false,
      pagination: {
        per_page: 15,
        current_page: 1,
        total: 0,
      },
      cid: `uid-${this._uid.toString()}`,
      btnState: false,
      rowsPerPage: null,
      iconComponent: null,
    };
  },
  computed: {
    iconLoader() {
      if (!this.$attrs.fields?.find((field) => field.key === 'kmtx-more')) {
        return () => Promise.resolve();
      }
      return () => import(/* webpackMode: "eager" */'~/components/IconsComponent.vue');
    },
    totalRows() {
      return this.pagination.total || this.items?.length;
    },
    fieldsKeys() {
      return this.$attrs?.fields.map((el) => el.key) || [];
    },
  },
  watch: {
    nestedTableData: {
      handler(to) {
        this.nestedTableProp = to && !!Object.keys(to).length;
      },
      immediate: true,
    },
    perPage(val) {
      if (val) {
        this.rowsPerPage = val;
      }
    },
  },
  created() {
    this.iconLoader().then((component) => {
      if (component) {
        this.iconComponent = () => this.iconLoader();
      }
    });
    this.pagination.current_page = this.$attrs['current-page'] || this.pagination.current_page;
    this.rowsPerPage = this.pagination.per_page = this.perPage || this.$attrs['per-page'] || this.pagination.per_page;
    EventBus.$on('kmtx-table-refresh', this.tableRefresh);
  },
  beforeDestroy() {
    EventBus.$off('kmtx-table-refresh', this.tableRefresh);
  },
  methods: {
    kFormatter(count) {
      return kFormatter(count);
    },
    tableRefresh(id) {
      if (this.$attrs.id === id) {
        this.$refs.table.refresh();
      }
    },
    sortChanged(context) {
      if (context.currentPage > 1) {
        this.pagination.current_page = 1;
      }
    },
    dataProvider(ctx) {
      this.isBusy = true;
      const params = ctx;
      if (this.providerParams) {
        Object.assign(params, { ...this.providerParams });
      }
      return this.providerSource(params).then(({ data: { data = [], meta = {} } }) => {
        const items = data;
        this.pagination.total = meta.total;
        return items || [];
      }).catch(() => []).finally(() => {
        this.isBusy = false;
      });
    },
    perPageFormatter(value) {
      const result = this.perPageCorrecter(value);
      this.rowsPerPage = result;
      return result;
    },
    perPageOnUpdate(value) {
      this.rowsPerPage = this.perPageCorrecter(value);
    },
    perPageCorrecter(value) {
      const regex = /\d+/g;
      const match = value.match(regex);
      return (match && match[0] > 0) ? parseInt(match[0], 10) : this.perPage;
    },
    onFiltered(filteredItems) {
      if (this.pagination.total === filteredItems.length) return;
      this.pagination.total = filteredItems.length || 1;
      this.pagination.current_page = 1;
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables";

.table-component {
  &__dropdown {
    .dropdown-toggle {
      border-color: transparent;

      &:hover {
        background-color: $color-white;

        svg path {
            fill: var(--primary)
        }
      }
    }
  }
}

.b-table-details .table-container,
.table-component__nested {
  border-bottom: 2rem solid $light-grey-1;
}

.b-table-details {
  .table-container {
    border: 1px solid rgba(51, 77, 153, 0.15) !important;
    border-top: none !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  & + tr td {
    border-top: none;
  }
}

.table-container .table th,
.b-table-details .table th {
  border-top: none;
}

.table-container.container-fluid {
  padding: 0;
}

.table tbody td {
  vertical-align: middle;
  height: 48px;
}

.paginator .page-item.active .page-link {
  background-color: $light-grey-1;
  border-color: $light-grey-1;
  color: $dark-grey-1;
  font-weight: 600;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
}

.table-container .b-table-has-details {
  td {
      border: solid 1px transparent;
      border-style: solid none;
      border-bottom: none;
      border-top-color: rgba(51, 77, 153, .15) !important;

      &:first-child {
        border-left: 1px solid rgba(51, 77, 153, .15) !important;
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-right: 1px solid rgba(51, 77, 153, .15) !important;
        border-top-right-radius: 10px;
      }
  }
}

.thead-kmtx:not(.sr-only) {
  background: $light-grey-1;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table .thead-kmtx th {
  vertical-align: middle;
  background-color: $light-grey-1;
  border-color: $light-grey-6;
}

.table.b-table .thead-kmtx > tr > th {
  background-position: right calc(1.75rem / 2) center;
}

.table-container .btn-kmtx {
    display: inline-block;
    transition: .3s transform;
}

.table-container .b-table-has-details .btn-kmtx {
  transform: rotate(90deg);
}

.table-container .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.table-container > .grid {
  padding-inline: 15px;
}

.table-container__head-controls {
  align-items: flex-end;
  padding-bottom: .5em;
  .pagination-total {
    margin-right: 20px;
    margin-bottom: 0.5rem;
    line-height: 28px;
  }
}

.table-container__head-controls .form-control {
  display: initial;
  max-width: 50px;
  height: calc(1.5em + 0.5rem + 1px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.table-container .b-table-sticky-header {
  max-height: 305px;
  margin-bottom: 0;
}

.table-component--nested-full-size .table-container .b-table-sticky-header,
.table-component--nested-full-size .table-component__nested.b-table-sticky-header {
  max-height: none;
}

.table td.table-cell--expand {
  padding: 0;
  vertical-align: middle;
  text-align: center;
}

tr.b-table-details > td {
  padding: 0;
}

.table-container .table td {
  vertical-align: middle;
}

.table-hover tbody tr.b-table-details:hover {
  background: transparent;
}

.table-container .b-table-has-details:focus {
  outline: none;
}

.table-transparent-design {
    .b-table-sticky-header {
        max-height: calc(100vh - 14.25rem);
    }

    .table {
    .thead-kmtx {
        box-shadow: 0 4px 10px rgba(0, 0, 0, .08);
    }

    .thead-kmtx th {
        background-color: $color-white;
        border-width: 1px;
        color: $archivedStatusColor;
    }

    .thead-kmtx th,
    tbody tr td {
        border-color: $light-grey-6;
    }

    tbody tr td {
        color: $grey-2;

        &:first-of-type {
            overflow: hidden;
        }
    }

    .icon-wrapper {
        overflow: hidden;
    }

    tr:not(.b-table-has-details, .b-table-details, .b-table-details tr):hover {
        background-color: $gray50;
    }

    tr {
        cursor: default;
    }
    }
}
</style>
