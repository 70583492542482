import * as validators from '@vuelidate/validators';
import { isIP } from 'is-ip';
import isCidr from 'is-cidr';
import { ValidationRule } from '@vuelidate/core';
import { ComputedRef, Ref } from 'vue';
import validationMessages from './validationMessages';
import capitalize from './capitalize';

interface ValidationParam {
    model: string;
    property: string;
    [key: string]: any;
}
const { createI18nMessage } = validators;

function replacePlaceholders(template: string, values: ValidationParam) {
    const regex = /{([^}]+)}/g;
    return template.replace(regex, (match, placeholder) => {
        if (!Object.hasOwn(values, placeholder)) {
            return match;
        }
        return placeholder === 'property' && values.nameForMessage ? values.nameForMessage : `${values[placeholder]}`.replace(/_/g, ' ');
    });
}

function translationFunction(path: string, params: ValidationParam): string {
    const args = {
        ...params,
        property: capitalize(params.property),
    };
    return replacePlaceholders(validationMessages.validations[path], args);
}

export function validateIP(...params) {
    const [value] = params;

    const isCloudsqlproxy = /^cloudsqlproxy~(?:(?:(?:25[0-5]|(?:2[0-4]|1\d|[1-9]|)\d)\.?\b){4})$/.test(value);
    const isMySQLWildCard =
        /^(((((25[0-5]|(?:2[0-4]|[1-9])\d?)))\.((((25[0-5]|(?:2[0-4]|[1-9])\d?)|%)\.){2})(((25[0-5]|(?:2[0-4]|[1-9])\d?)|%)))|%)$/.test(
            value
        );
    return isCloudsqlproxy || isMySQLWildCard || isIP(value) || !!isCidr(value);
}

const messagePath = ({ $validator }) => $validator;
const withI18nMessage = createI18nMessage({ t: translationFunction, messagePath });
const createValidator = (type: string, validatorFn: ValidationRule<any>, nameForMessage?: string) =>
    withI18nMessage(validators.helpers.withParams({ type, nameForMessage }, validatorFn));

export const required = (nameForMessage?: string) => createValidator('required', validators.required, nameForMessage);
export const maxLength = (param: number, nameForMessage?: string) =>
    createValidator('maxLength', validators.maxLength(param), nameForMessage);
export const minLength = (param: number, nameForMessage?: string) =>
    createValidator('minLength', validators.minLength(param), nameForMessage);
export const minValue = (param: number, nameForMessage?: string) => createValidator('minValue', validators.minValue(param), nameForMessage);
export const maxValue = (param: number, nameForMessage?: string) => createValidator('maxValue', validators.maxValue(param), nameForMessage);
export const onlyNumbers = (nameForMessage?: string) =>
    createValidator('onlyNumbers', validators.helpers.regex(/^[0-9]*$/), nameForMessage);
export const sameAs = (param: number | Ref<number> | string | ComputedRef<string>, nameForMessage?: string) =>
    createValidator('sameAs', validators.sameAs(param), nameForMessage);

export const email = withI18nMessage(validators.email);
export const numeric = withI18nMessage(validators.numeric);
export const integer = withI18nMessage(validators.integer);

export const validIp = withI18nMessage(validateIP);
export const latinNameOnly = withI18nMessage(validators.helpers.regex(/^[a-zA-Z\-'` ]*$/));
export const lettersNumbers = withI18nMessage(validators.helpers.regex(/^[a-z\-_0-9]*$/i));
export const lettersNumbersWithPointsBrackets = withI18nMessage(validators.helpers.regex(/^[a-zA-Z0-9-.() ]+$/));
export const slug = withI18nMessage(validators.helpers.regex(/^([a-z0-9]+(?:-[a-z0-9]+)*)(?::[a-z0-9]+(?:-[a-z0-9]+)*)$/));
export const slugPrefix = withI18nMessage(validators.helpers.regex(/^[a-z0-9]*$/));
export const upperCase = withI18nMessage(validators.helpers.regex(/[A-Z]/));
export const lowerCase = withI18nMessage(validators.helpers.regex(/[a-z]/));
export const leastOneSymbol = withI18nMessage(validators.helpers.regex(/[!"#$%&'()*+,\-./\\:;<=>?@[\]^_`{|}~]/));
export const leastOneNumber = withI18nMessage(validators.helpers.regex(/[0-9]/));

export const notAllowedNumberSymbol = withI18nMessage(validators.helpers.regex(/^[^№]*$/));
export const noSpaces = withI18nMessage(validators.helpers.regex(/^[^\s]*$/));
export const noCyrillic = withI18nMessage(validators.helpers.regex(/^[^\u0400-\u04FF]*$/));
export const roleName = withI18nMessage(validators.helpers.regex(/^[a-zA-Z0-9\-'`_. ]*$/));
export const labelName = withI18nMessage(validators.helpers.regex(/^[a-z0-9\-#_:]*$/));
export const link = withI18nMessage(validators.helpers.regex(/\bhttps?:\/\/[^\s/$.?#].[^\s]*\b/));
export const onlyAutodocEmails = withI18nMessage(validators.helpers.regex(/^[a-zA-Z0-9._%+-]+@(autodoc\.eu|autodoc\.de|autodoc\.pl)$/));
// Need fix for server side validation
export const placeholderForServerSideValidation = () => true;
export const { helpers } = validators;
