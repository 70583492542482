var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.KFlexContainer,{staticClass:"reset-password w-100",attrs:{"wrap":"nowrap","justifyContent":"center","alignItems":"center"}},[(!_setup.tokenError)?_c(_setup.KFlexContainer,{staticClass:"reset-password-wrapper",attrs:{"direction":"column","justifyContent":"center","alignItems":"center","wrap":"nowrap"}},[_c(_setup.Header,{attrs:{"show-navigation":false,"show-env-accounts-user-menu":false}}),_c(_setup.Logo,{staticClass:"reset-password-logo",attrs:{"id":"loginFormLogo","link":false,"imagePath":"/images/logo.svg"}}),_c('form',{staticClass:"reset-password-form",on:{"submit":function($event){$event.preventDefault();return _setup.submitForm.apply(null, arguments)}}},[_c(_setup.KText,{staticClass:"reset-password-header",attrs:{"size":"lg"}},[_vm._v("Please enter a new password")]),_c(_setup.KInput,{attrs:{"label":"Email","disabled":true},model:{value:(_setup.formValue.email),callback:function ($$v) {_vm.$set(_setup.formValue, "email", $$v)},expression:"formValue.email"}}),_c(_setup.FormControlWithError,{staticClass:"mt-3",scopedSlots:_vm._u([{key:"control",fn:function(){return [_c(_setup.KFlexContainer,{staticClass:"w-100",attrs:{"alignItems":"center","justifyContent":"flex-end"}},[_c(_setup.KInput,{class:{
                                'reset-password-valid-feedback':
                                    _setup.resetPasswordForm.password.$dirty && !_setup.resetPasswordForm.password.$invalid,
                            },attrs:{"type":_setup.visiblePassword.password ? 'text' : 'password',"label":"New password","required":"","invalid":_setup.isInvalidAndTouched('password')},on:{"blur":function($event){return _setup.onValidation('password')}},model:{value:(_setup.formValue.password),callback:function ($$v) {_vm.$set(_setup.formValue, "password", $$v)},expression:"formValue.password"}}),(_setup.formValue.password)?_c(_setup.KHint,{staticClass:"reset-password-input-icon",attrs:{"textContent":_setup.visiblePassword.password ? 'Hide password' : 'Show password',"position":"bottom-left","width":200}},[(_setup.formValue.password)?_c(_setup.KIconButton,{attrs:{"type":"button","size":"sm","icon":_setup.visiblePassword.password ? 'hide-outline' : 'show-outline',"disabled":false,"color":"base"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _setup.togglePasswordInputType($event, 'password')}}}):_vm._e()],1):_vm._e()],1)]},proxy:true},{key:"error",fn:function(){return [(_setup.isInvalidAndTouched('password'))?_c(_setup.ErrorsMessage,{staticClass:"reset-password-error",attrs:{"errors":_setup.resetPasswordForm.password.$errors,"newLine":""}}):_vm._e()]},proxy:true}],null,false,2140355266)}),_c(_setup.FormControlWithError,{staticClass:"mt-3 mb-0",scopedSlots:_vm._u([{key:"control",fn:function(){return [_c(_setup.KFlexContainer,{staticClass:"w-100",attrs:{"alignItems":"center","justifyContent":"flex-end"}},[_c(_setup.KInput,{class:{
                                'reset-password-valid-feedback':
                                    _setup.resetPasswordForm.confirmPassword.$dirty && !_setup.resetPasswordForm.confirmPassword.$invalid,
                            },attrs:{"type":_setup.visiblePassword.confirmPassword ? 'text' : 'password',"label":"Confirm new password","required":"","invalid":_setup.isInvalidAndTouched('confirmPassword')},on:{"blur":function($event){return _setup.onValidation('confirmPassword')}},model:{value:(_setup.formValue.confirmPassword),callback:function ($$v) {_vm.$set(_setup.formValue, "confirmPassword", $$v)},expression:"formValue.confirmPassword"}}),(_setup.formValue.confirmPassword)?_c(_setup.KHint,{staticClass:"reset-password-input-icon",attrs:{"textContent":_setup.visiblePassword.confirmPassword ? 'Hide password' : 'Show password',"position":"bottom-left","width":200}},[(_setup.formValue.confirmPassword)?_c(_setup.KIconButton,{attrs:{"type":"button","size":"sm","icon":_setup.visiblePassword.confirmPassword ? 'hide-outline' : 'show-outline',"disabled":false,"color":"base"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _setup.togglePasswordInputType($event, 'confirmPassword')}}}):_vm._e()],1):_vm._e()],1)]},proxy:true},{key:"error",fn:function(){return [(_setup.isInvalidAndTouched('confirmPassword'))?_c(_setup.ErrorsMessage,{attrs:{"errors":_setup.resetPasswordForm.confirmPassword.$errors,"newLine":""}}):_vm._e()]},proxy:true}],null,false,2756094864)}),_c(_setup.KButton,{staticClass:"w-100 reset-password-submit",attrs:{"variant":"primary","type":"submit","label":"Change password"}})],1)],1):_c(_setup.KText,{attrs:{"size":"lg"}},[_vm._v("Current reset password link was expired or is invalid.")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }