import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

const servicesBrowserTitle = 'Services - IAM - Data Platform';

export default {
  path: '/services',
  component: RoutingViewContainer,
  redirect: { name: 'service-management-list' },
  children: [
    {
      path: 'service-management-list',
      component: () => import(/* webpackChunkName: "[Services]" */ '@/modules/service-management/pages/Services.vue'),
      name: 'service-management-list',
      meta: {
        permission: 'sm:services:service:view',
        allowForMaster: true,
        masterAccountOnly: true,
        browserTitle: `${servicesBrowserTitle}`,
      },
    },
    {
      path: 'service-management',
      component: () => import(/* webpackChunkName: "[Service]" */ '@/modules/service-management/pages/Service.vue'),
      name: 'service-management',
      children: [
        {
          path: 'create',
          component: () => import(/* webpackChunkName: "[Service General]" */ '@/modules/service-management/pages/ServiceGeneral.vue'),
          name: 'service-management-create',
          meta: {
            permission: 'sm:services:service:edit',
            masterAccountOnly: true,
            allowForMaster: true,
            browserTitle: `Create Services - ${servicesBrowserTitle}`,
          },
        },
        {
          path: ':service/general',
          component: () => import(/* webpackChunkName: "[Service General]" */ '@/modules/service-management/pages/ServiceGeneral.vue'),
          name: 'service-management-edit-general',
          meta: {
            permission: 'sm:services:service:edit',
            masterAccountOnly: true,
            allowForMaster: true,
            browserTitle: `Edit Service - ${servicesBrowserTitle}`,
          },
        },
        {
          path: ':service/accounts',
          component: () => import(/* webpackChunkName: "[Service Accounts]" */ '@/modules/service-management/pages/UnderConstruction.vue'),
          name: 'service-management-edit-accounts',
          meta: {
            permission: 'sm:services:service:edit',
            masterAccountOnly: true,
            allowForMaster: true,
            browserTitle: `Edit Service - ${servicesBrowserTitle}`,
          },
        },
        {
          path: ':service/keys',
          component: () => import(/* webpackChunkName: "[Service Tokens]" */ '@/modules/service-management/pages/ServiceAPIKeys.vue'),
          name: 'service-management-keys-list',
          meta: {
            permission: 'sm:services:api-key:view',
            masterAccountOnly: true,
            allowForMaster: true,
            browserTitle: `Edit Service API Keys - ${servicesBrowserTitle}`,
          },
        },
        {
          path: ':service/ds_access',
          component: () => import(/* webpackChunkName: "[Service DS Access]" */ '@/modules/service-management/pages/UnderConstruction.vue'),
          name: 'service-management-edit-ds-access',
          meta: {
            permission: 'sm:services:service:edit',
            masterAccountOnly: true,
            allowForMaster: true,
            browserTitle: `Edit Service - ${servicesBrowserTitle}`,
          },
        },
        {
          path: ':service/permissions/create',
          component: () => import(/* webpackChunkName: "[Permissions]" */ '@/modules/service-management/pages/ServicePermissions.vue'),
          name: 'service-management-create-permissions',
          meta: {
            permission: 'sm:services:service:create',
            masterAccountOnly: true,
            allowForMaster: true,
            browserTitle: `Сreate Service Permissions - ${servicesBrowserTitle}`,
          },
        },
        {
          path: ':service/permissions/:permission',
          component: () => import(/* webpackChunkName: "[Permissions]" */ '@/modules/service-management/pages/ServicePermissions.vue'),
          name: 'service-management-edit-permissions',
          meta: {
            permission: 'sm:services:service:edit',
            masterAccountOnly: true,
            allowForMaster: true,
            browserTitle: `Edit Service Permissions - ${servicesBrowserTitle}`,
          },
        },
      ],
    },
  ],
};
