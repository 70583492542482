import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

const Users = () => import(/* webpackChunkName: "[IAMUsers]" */ '@/modules/user-management/pages/UserList.vue');
const iamBrowserTitle = 'IAM - Data Platform';
const iamUsersBrowserTitle = `Users - ${iamBrowserTitle}`;
const iamAccountsBrowserTitle = `Accounts - ${iamBrowserTitle}`;

export default {
    path: '/iam',
    component: RoutingViewContainer,
    name: 'iam',
    redirect: { name: 'iam-users' },
    children: [
        {
            path: 'users',
            component: Users,
            name: 'iam-users',
            meta: {
                permission: 'iam:users:tab',
                allowForMaster: true,
                browserTitle: `${iamUsersBrowserTitle}`,
            },
        },
        {
            path: 'users/user',
            component: () => import(/* webpackChunkName: "[UserCreate]" */ '@/modules/user-management/pages/UserCreate.vue'),
            name: 'iam-user',
            props: true,
            meta: { scope: 6, allowForMaster: true, browserTitle: `Create User - ${iamUsersBrowserTitle}` },
            beforeEnter: (...params) => {
                const [to, , next] = params;
                if (to.query && to.query.id) {
                    next({ name: 'iam-user-edit', params: { id: to.query.id } });
                } else {
                    next();
                }
            },
        },
        {
            path: 'users/user-invite',
            component: () => import(/* webpackChunkName: "[UserInvite]" */ '@/modules/user-management/pages/UserInvite.vue'),
            name: 'iam-user-invite',
            props: true,
            meta: { scope: 6, allowForMaster: true, browserTitle: `Invite User - ${iamUsersBrowserTitle}` },
        },
        {
            path: 'users/user/:id',
            component: () => import(/* webpackChunkName: "[UserEdit]" */ '@/modules/user-management/pages/UserEdit.vue'),
            name: 'iam-user-edit',
            props: true,
            meta: { scope: 6, allowForMaster: true, browserTitle: `Edit User - ${iamUsersBrowserTitle}` },
        },
        {
            path: 'roles',
            component: () => import(/* webpackChunkName: "[Roles]" */ '@/modules/user-management/pages/Roles.vue'),
            name: 'iam-roles',
            meta: {
                header: { title: 'Roles', tab: true },
                permission: 'iam:roles:tab',
                allowForMaster: true,
                browserTitle: `Roles - ${iamBrowserTitle}`,
            },
        },
        {
            path: 'roles/role',
            component: () => import(/* webpackChunkName: "[Role]" */ '@/modules/user-management/pages/Role.vue'),
            name: 'iam-role',
            props: true,
            meta: {
                permission: 'iam:roles:tab',
                allowForMaster: true,
                browserTitle: `Edit Role - Roles - ${iamBrowserTitle}`,
            },
        },
        {
            path: 'accounts',
            component: () => import(/* webpackChunkName: "[Accounts]" */ '@/modules/user-management/pages/Accounts.vue'),
            name: 'iam-accounts',
            meta: {
                header: { title: 'Accounts', tab: true },
                permission: 'iam:accounts:tab',
                allowForMaster: true,
                browserTitle: `${iamAccountsBrowserTitle}`,
            },
        },
        {
            path: 'accounts/account',
            component: () => import(/* webpackChunkName: "[AccountCreate]" */ '@/modules/user-management/pages/AccountCreate.vue'),
            name: 'iam-account',
            meta: {
                permission: 'iam:accounts:tab',
                allowForMaster: true,
                browserTitle: `Account - ${iamBrowserTitle}`,
            },
        },
        {
            path: 'accounts/account/:id',
            component: () => import(/* webpackChunkName: "[AccountEdit]" */ '@/modules/user-management/pages/AccountEdit.vue'),
            name: 'iam-account-edit',
            meta: {
                permission: 'iam:accounts:tab',
                allowForMaster: true,
                browserTitle: `Edit Account - ${iamAccountsBrowserTitle}`,
            },
        },
    ],
};
